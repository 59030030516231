import React from 'react'
import { useState, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

let stripePromise

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
  }

  return stripePromise
}

function Pricing() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [stripeError, setStripeError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const history = useHistory()
  const [email, setEmail] = useState('')

  useEffect(() => {
    // Retrieve the value from localStorage
    const storedValue = localStorage.getItem('isLoggedIn')
    try {
      const userJSON = localStorage.getItem('user')
      console.log('Stored JSON:', userJSON)

      // Parse the JSON string to an object
      const userObject = JSON.parse(userJSON)

      // Access the email property
      const userName = userObject.name
      const userEmail = userObject.email

      setEmail(userEmail)
    } catch (err) {}
    // If the stored value is 'true', set isLoggedIn to true
    if (storedValue === 'true') {
      setLoggedIn(true)
    }
    console.log('stripe key', process.env.REACT_APP_STRIPE_KEY)
  }, [])
  useEffect(() => {
    // Define your Paddle configuration
    const paddleConfig = {
      seller: 176943, // replace with your seller ID
      pwAuth: 'a9bea13a9cbecaba7f0018d5981a83b1cc1b5f775e5ff02c99', // replace with your Retain API key
      checkout: {
        settings: {
          theme: 'dark',
          locale: 'us',
        },
      },
      eventCallback: function (data) {
        console.log('eventCallback', data)
        if (data.name === 'checkout.completed') {
          console.log(data)
          window.Paddle.Checkout.close()
          history.push('/paymentsuccess')
        }
      },
    }

    // Load Paddle.js and initialize it with your configuration
    const script = document.createElement('script')
    script.src = 'https://cdn.paddle.com/paddle/v2/paddle.js'
    script.async = true
    script.onload = () => {
      //window.Paddle.Environment.set('live')
      window.Paddle.Setup(paddleConfig)
    }

    document.body.appendChild(script)

    // Ensure the script is removed when the component unmounts
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const processFree = async () => {
    if (loggedIn) {
      history.push('/')
    } else {
      history.push('/register')
    }
  }
  const processProAnnualPayment = async () => {
    if (loggedIn) {
      if (window.setClarityAnnualPlanClicked) {
        window.setClarityAnnualPlanClicked()
      }
      var itemsList = [
        {
          priceId: 'pri_01h985rzvtach3xqb7gjh18zat',
          quantity: null,
        },
      ]
      window.Paddle.Checkout.open({
        settings: {
          displayMode: 'overlay',
          theme: 'dark',
          locale: 'en',
          // successUrl: 'http://localhost:3000/paymentsuccess',

          //  successUrl: 'https://aiflowchart.io/paymentsuccess',
        },
        items: itemsList,
        customer: { email },

        customData: { email },
      })
      // toast.success(
      //   'Due to issues with the payment gateway, we have temporarily suspended access to premium subscriptions.',
      //   {
      //     type: 'error',
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: 0,
      //   },
      // )
    } else {
      toast.success(
        'Access Restricted: To use this feature, please log in or sign up for an account.',
        {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        },
      )
    }
  }

  const processBasicPayment = async () => {
    if (loggedIn) {
      if (window.setClarityProPlanClicked) {
        window.setClarityProPlanClicked()
      }
      var itemsList = [
        {
          priceId: 'pri_01h985m19wwearcb4eng236psr',
          quantity: null,
        },
      ]
      window.Paddle.Checkout.open({
        settings: {
          displayMode: 'overlay',
          theme: 'dark',
          locale: 'en',
          // successUrl: 'http://localhost:3000/paymentsuccess',

          //  successUrl: 'https://aiflowchart.io/paymentsuccess',
        },
        items: itemsList,
        customer: { email },

        customData: { email },
      })
      // toast.success(
      //   'Due to issues with the payment gateway, we have temporarily suspended access to premium subscriptions.',
      //   {
      //     type: 'error',
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: 0,
      //   },
      // )
    } else {
      toast.success(
        'Access Restricted: To use this feature, please log in or sign up for an account.',
        {
          type: 'error',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        },
      )
    }
  }

  const processProPayment = async () => {
    toast.success(
      'Sorry, this plan is not currently available. Please check back later or explore other options.',
      {
        type: 'error',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      },
    )
  }

  return (
    <div
      style={{
        boxSizing: 'border-box',
        border: '0px solid rgb(229, 231, 235)',
      }}
    >
      <div
        style={{
          boxSizing: 'border-box',
          border: '0px solid rgb(229, 231, 235)',
          display: 'flex',
          WebkitBoxPack: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: '0px',
        }}
      >
        <main
          style={{
            margin: '20px auto 0px',
            boxSizing: 'border-box',
            border: '0px solid rgb(229, 231, 235)',
            display: 'block',
            width: '100%',
            zIndex: 0,
            position: 'relative',
          }}
        >
          <section
            style={{
              boxSizing: 'border-box',
              border: '0px solid rgb(229, 231, 235)',
              display: 'flex',
              flexDirection: 'column',
              WebkitBoxAlign: 'center',
              alignItems: 'center',
              padding: '0px',
            }}
          >
            <div
              style={{
                boxSizing: 'border-box',
                border: '0px solid rgb(229, 231, 235)',
                margin: '0px auto 40px',
                textAlign: 'center',
              }}
            >
              <h1
                style={{
                  fontWeight: 700,
                  fontSize: '52px',
                  lineHeight: '65px',
                  marginTop: '100px',
                  marginBottom: '100px',
                  color: 'rgb(250, 250, 250)',
                  boxSizing: 'border-box',
                  margin: '0px',
                  border: '0px solid rgb(229, 231, 235)',
                }}
              >
                Generate flowchart with AI. Start{' '}
                <span
                  style={{
                    backgroundColor: 'rgb(255, 0, 0)',
                    backgroundImage:
                      'linear-gradient(90deg, rgb(246, 73, 66), rgb(239, 144, 164), rgb(91, 149, 191))',
                    backgroundSize: '100%',
                    backgroundRepeat: 'repeat',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'rgba(0, 0, 0, 0)',
                    boxSizing: 'border-box',
                    border: '0px solid rgb(229, 231, 235)',
                  }}
                >
                  free
                </span>
                .
              </h1>
            </div>
            <section
              style={{
                maxWidth: '1200px',
                boxSizing: 'border-box',
                border: '0px solid rgb(229, 231, 235)',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  marginLeft: '30px',
                  marginRight: '30px',
                  boxSizing: 'border-box',
                  border: '0px solid rgb(229, 231, 235)',
                  display: 'flex',
                  WebkitBoxPack: 'center',
                  justifyContent: 'center',
                  gap: '40px',
                  marginBottom: '100px',
                }}
              >
                <section
                  style={{
                    boxSizing: 'border-box',
                    border: '0px solid rgb(229, 231, 235)',
                    width: '45%',
                    padding: '32px 28px',
                    minHeight: '300px',
                    WebkitBoxAlign: 'center',
                    alignItems: 'center',
                    background:
                      'rgba(0, 0, 0, 0) none repeat scroll 0% 0% / auto padding-box border-box',
                    boxShadow:
                      'rgba(255, 255, 255, 0.15) 0px 1px 3px 0px inset, rgb(33, 40, 49) 0px -1px 3px 0px inset',
                    borderRadius: '24px',
                  }}
                >
                  <div
                    style={{
                      boxSizing: 'border-box',
                      border: '0px solid rgb(229, 231, 235)',
                      marginBottom: '4px',
                    }}
                  >
                    <h3
                      style={{
                        fontWeight: 500,
                        lineHeight: '30.72px',
                        fontSize: '24px',
                        marginTop: '0px',
                        marginBottom: '8px',
                        color: 'rgb(250, 250, 250)',
                        boxSizing: 'border-box',
                        margin: '0px 0px 8px',
                        border: '0px solid rgb(229, 231, 235)',
                      }}
                    >
                      Basic Plan
                    </h3>
                    <p
                      style={{
                        marginBottom: '0px',
                        boxSizing: 'border-box',
                        border: '0px solid rgb(229, 231, 235)',
                        minHeight: '42px',
                        fontSize: '15px',
                        lineHeight: '21px',
                        color: 'rgb(144, 152, 162)',
                      }}
                    >
                      Ideal for Personal usecase
                    </p>
                  </div>
                  <div
                    style={{
                      boxSizing: 'border-box',
                      border: '0px solid rgb(229, 231, 235)',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <p
                      style={{
                        boxSizing: 'border-box',
                        margin: '0px 0px 4px',
                        border: '0px solid rgb(229, 231, 235)',
                        display: 'flex',
                        WebkitBoxAlign: 'center',
                        alignItems: 'center',
                        marginTop: '0px',
                        marginBottom: '4px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: '52px',
                          lineHeight: '58px',
                          marginTop: '0px',
                          marginBottom: '8px',
                          color: 'rgb(250, 250, 250)',
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                        }}
                      >
                        <sup
                          style={{
                            boxSizing: 'border-box',
                            top: '-14.3px',
                            fontSize: '28.6px',
                            lineHeight: '0px',
                            position: 'relative',
                            verticalAlign: 'baseline',
                            border: '0px solid rgb(229, 231, 235)',
                          }}
                        ></sup>
                        Free
                      </span>
                      {/* <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          fontSize: '14px',
                          marginTop: '-8px',
                          marginLeft: '12px',
                          lineHeight: '18px',
                        }}
                      >
                        per month
                      </span> */}
                    </p>
                    {/* <span
                      style={{
                        boxSizing: 'border-box',
                        border: '0px solid rgb(229, 231, 235)',
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '60px',
                      }}
                    >
                      <p
                        style={{
                          boxSizing: 'border-box',
                          margin: '0px',
                          border: '0px solid rgb(229, 231, 235)',
                          color: 'rgb(144, 152, 162)',
                          fontSize: '14px',
                          lineHeight: '21px',
                        }}
                      >
                        billed monthly
                      </p>
                    </span> */}
                    <button
                      style={{
                        boxSizing: 'border-box',
                        cursor: 'pointer',
                        appearance: 'button',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        backgroundImage: 'none',
                        textTransform: 'none',
                        fontFamily:
                          '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '27.52px',
                        color: 'rgb(250, 250, 250)',
                        margin: '0px',
                        padding: '0px',
                        border: '0px none rgb(250, 250, 250)',
                        overflow: 'visible',
                        display: 'flex',
                        width: '100%',
                        position: 'relative',
                        outline: 'rgb(250, 250, 250) none 0px',
                      }}
                      onClick={processFree}
                    >
                      <div
                        style={{
                          maxWidth: '100%',
                          cursor: 'pointer',
                          borderWidth: '0px',
                          borderRadius: '8px',
                          backgroundColor: 'rgb(255, 45, 96)',
                          color: 'rgb(250, 250, 250)',
                          boxSizing: 'border-box',
                          padding: '9.6px 24px',
                          transition:
                            'color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s',
                          margin: '0px',
                          fontFamily:
                            '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                          fontSize: '16px',
                          border: '0px none rgb(250, 250, 250)',
                          fontWeight: 600,
                          display: 'flex',
                          width: '100%',
                          placeContent: 'stretch center',
                          WebkitBoxPack: 'center',
                          WebkitBoxAlign: 'center',
                          alignItems: 'center',
                          height: '56px',
                        }}
                      >
                        <span
                          style={{
                            boxSizing: 'border-box',
                            border: '0px solid rgb(229, 231, 235)',
                            opacity: 1,
                            transition: 'opacity 0.15s ease-in-out 0s',
                            margin: '0px',
                          }}
                        >
                          Get Started
                        </span>
                      </div>
                    </button>
                    <p
                      style={{
                        fontSize: '18px',
                        color: 'rgb(144, 152, 162)',
                        fontFamily:
                          '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                        fontWeight: 400,
                        lineHeight: '30.96px',
                        boxSizing: 'border-box',
                        margin: '0px 0px 24px',
                        border: '0px solid rgb(229, 231, 235)',
                      }}
                    >
                      Everything{' '}
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          fontWeight: 600,
                        }}
                      >
                        Free
                      </span>
                      {/* , plus: */}
                    </p>
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '16px',
                        fontWeight: 400,
                        marginTop: '0px',
                        marginBottom: '8px',
                        boxSizing: 'border-box',
                        border: '0px solid rgb(229, 231, 235)',
                        display: 'grid',
                        gridTemplateColumns: '20px auto',
                        gap: '8px',
                        color: 'rgb(250, 250, 250)',
                      }}
                    >
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                        }}
                      ></span>
                      <div
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                        }}
                      >
                        <button
                          data-state="closed"
                          style={{
                            boxSizing: 'border-box',
                            cursor: 'pointer',
                            appearance: 'button',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            backgroundImage: 'none',
                            textTransform: 'none',
                            fontFamily:
                              '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            color: 'rgb(250, 250, 250)',
                            margin: '0px',
                            padding: '0px',
                            border: '0px solid rgb(229, 231, 235)',
                            overflow: 'visible',
                          }}
                        >
                          <div
                            style={{
                              boxSizing: 'border-box',
                              border: '0px solid rgb(229, 231, 235)',
                              textAlign: 'left',
                            }}
                          >
                            <span
                              style={{
                                boxSizing: 'border-box',
                                border: '0px solid rgb(229, 231, 235)',
                                fontWeight: 700,
                                background:
                                  'rgba(0, 0, 0, 0) linear-gradient(to right, rgb(255, 45, 96), rgb(255, 163, 102), rgb(255, 215, 0), rgb(198, 255, 171), rgb(112, 214, 255), rgb(199, 125, 255), rgb(255, 45, 96)) repeat scroll 8.14704% 50% / 200% 100% padding-box',
                                WebkitBackgroundClip: 'text',
                                color: 'rgba(0, 0, 0, 0)',
                                animation:
                                  '4s ease 0s infinite normal none running hLEcf',
                              }}
                            >
                              GPT 3.5
                            </span>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '16px',
                        fontWeight: 400,
                        marginTop: '0px',
                        marginBottom: '8px',
                        boxSizing: 'border-box',
                        border: '0px solid rgb(229, 231, 235)',
                        display: 'grid',
                        gridTemplateColumns: '20px auto',
                        gap: '8px',
                        color: 'rgb(250, 250, 250)',
                      }}
                    >
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                        }}
                      ></span>
                      <div
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                        }}
                      >
                        <button
                          data-state="closed"
                          style={{
                            boxSizing: 'border-box',
                            cursor: 'pointer',
                            appearance: 'button',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            backgroundImage: 'none',
                            textTransform: 'none',
                            fontFamily:
                              '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            color: 'rgb(250, 250, 250)',
                            margin: '0px',
                            padding: '0px',
                            border: '0px solid rgb(229, 231, 235)',
                            overflow: 'visible',
                          }}
                        >
                          <div
                            style={{
                              boxSizing: 'border-box',
                              border: '0px solid rgb(229, 231, 235)',
                              textAlign: 'left',
                            }}
                          >
                            5 Credits
                          </div>
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '16px',
                        fontWeight: 400,
                        marginTop: '0px',
                        marginBottom: '8px',
                        boxSizing: 'border-box',
                        border: '0px solid rgb(229, 231, 235)',
                        display: 'grid',
                        gridTemplateColumns: '20px auto',
                        gap: '8px',
                        color: 'rgb(250, 250, 250)',
                      }}
                    >
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                        }}
                      ></span>
                      <div
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                        }}
                      >
                        <button
                          data-state="closed"
                          style={{
                            boxSizing: 'border-box',
                            cursor: 'pointer',
                            appearance: 'button',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            backgroundImage: 'none',
                            textTransform: 'none',
                            fontFamily:
                              '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            color: 'rgb(250, 250, 250)',
                            margin: '0px',
                            padding: '0px',
                            border: '0px solid rgb(229, 231, 235)',
                            overflow: 'visible',
                          }}
                        >
                          <div
                            style={{
                              boxSizing: 'border-box',
                              border: '0px solid rgb(229, 231, 235)',
                              textAlign: 'left',
                            }}
                          >
                            *
                          </div>
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '16px',
                        fontWeight: 400,
                        marginTop: '0px',
                        marginBottom: '8px',
                        boxSizing: 'border-box',
                        border: '0px solid rgb(229, 231, 235)',
                        display: 'grid',
                        gridTemplateColumns: '20px auto',
                        gap: '8px',
                        color: 'rgb(250, 250, 250)',
                      }}
                    >
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                        }}
                      ></span>
                      <div
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                        }}
                      >
                        <button
                          data-state="closed"
                          style={{
                            boxSizing: 'border-box',
                            cursor: 'pointer',
                            appearance: 'button',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            backgroundImage: 'none',
                            textTransform: 'none',
                            fontFamily:
                              '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            color: 'rgb(250, 250, 250)',
                            margin: '0px',
                            padding: '0px',
                            border: '0px solid rgb(229, 231, 235)',
                            overflow: 'visible',
                          }}
                        >
                          <div
                            style={{
                              boxSizing: 'border-box',
                              border: '0px solid rgb(229, 231, 235)',
                              textAlign: 'left',
                            }}
                          >
                            *
                          </div>
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '16px',
                        fontWeight: 400,
                        marginTop: '0px',
                        marginBottom: '8px',
                        boxSizing: 'border-box',
                        border: '0px solid rgb(229, 231, 235)',
                        display: 'grid',
                        gridTemplateColumns: '20px auto',
                        gap: '8px',
                        color: 'rgb(250, 250, 250)',
                      }}
                    >
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                        }}
                      ></span>
                      <div
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                        }}
                      >
                        <button
                          data-state="closed"
                          style={{
                            boxSizing: 'border-box',
                            cursor: 'pointer',
                            appearance: 'button',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            backgroundImage: 'none',
                            textTransform: 'none',
                            fontFamily:
                              '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            color: 'rgb(250, 250, 250)',
                            margin: '0px',
                            padding: '0px',
                            border: '0px solid rgb(229, 231, 235)',
                            overflow: 'visible',
                          }}
                        >
                          <div
                            style={{
                              boxSizing: 'border-box',
                              border: '0px solid rgb(229, 231, 235)',
                              textAlign: 'left',
                            }}
                          >
                            *
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  style={{
                    background:
                      'radial-gradient(at left top, rgba(246, 73, 66, 0.1), rgba(0, 0, 0, 0)) repeat scroll 0% 0% / auto padding-box border-box, radial-gradient(at right center, rgba(250, 219, 95, 0.1), rgba(0, 0, 0, 0)) repeat scroll 0% 0% / auto padding-box border-box, rgba(0, 0, 0, 0) radial-gradient(at right bottom, rgba(0, 148, 155, 0.15), rgba(0, 0, 0, 0)) repeat scroll 0% 0% / auto padding-box border-box',
                    boxSizing: 'border-box',
                    border: '0px solid rgb(229, 231, 235)',
                    width: '45%',
                    padding: '32px 28px',
                    minHeight: '300px',
                    WebkitBoxAlign: 'center',
                    alignItems: 'center',
                    boxShadow:
                      'rgba(255, 255, 255, 0.15) 0px 1px 3px 0px inset, rgb(33, 40, 49) 0px -1px 3px 0px inset',
                    borderRadius: '24px',
                  }}
                >
                  <div
                    style={{
                      boxSizing: 'border-box',
                      border: '0px solid rgb(229, 231, 235)',
                      marginBottom: '4px',
                    }}
                  >
                    <h3
                      style={{
                        fontWeight: 500,
                        lineHeight: '30.72px',
                        fontSize: '24px',
                        marginTop: '0px',
                        marginBottom: '8px',
                        color: 'rgb(250, 250, 250)',
                        boxSizing: 'border-box',
                        alignItems: 'center',
                        display: 'flex',
                        margin: '0px 0px 8px',
                        border: '0px solid rgb(229, 231, 235)',
                      }}
                    >
                      Pro Plan
                    </h3>
                    <p
                      style={{
                        marginBottom: '0px',
                        boxSizing: 'border-box',
                        border: '0px solid rgb(229, 231, 235)',
                        minHeight: '42px',
                        fontSize: '15px',
                        lineHeight: '21px',
                        color: 'rgb(144, 152, 162)',
                      }}
                    >
                      Business usecase
                    </p>
                  </div>
                  <div
                    style={{
                      boxSizing: 'border-box',
                      border: '0px solid rgb(229, 231, 235)',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <p
                      style={{
                        boxSizing: 'border-box',
                        margin: '0px 0px 4px',
                        border: '0px solid rgb(229, 231, 235)',
                        display: 'flex',
                        WebkitBoxAlign: 'center',
                        alignItems: 'center',
                        marginTop: '0px',
                        marginBottom: '4px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: '52px',
                          lineHeight: '58px',
                          marginTop: '0px',
                          marginBottom: '8px',
                          color: 'rgb(250, 250, 250)',
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                        }}
                      >
                        <sup
                          style={{
                            boxSizing: 'border-box',
                            top: '-14.3px',
                            fontSize: '28.6px',
                            lineHeight: '0px',
                            position: 'relative',
                            verticalAlign: 'baseline',
                            border: '0px solid rgb(229, 231, 235)',
                          }}
                        >
                          $
                        </sup>
                        4.99
                      </span>
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          fontSize: '14px',
                          marginTop: '-8px',
                          marginLeft: '12px',
                          lineHeight: '18px',
                        }}
                      >
                        per month
                      </span>
                    </p>

                    <button
                      style={{
                        boxSizing: 'border-box',
                        cursor: 'pointer',
                        appearance: 'button',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        backgroundImage: 'none',
                        textTransform: 'none',
                        fontFamily:
                          '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '27.52px',
                        color: 'rgb(250, 250, 250)',
                        margin: '0px',
                        padding: '0px',
                        border: '0px none rgb(250, 250, 250)',
                        overflow: 'visible',
                        display: 'flex',
                        width: '100%',
                        position: 'relative',
                        outline: 'rgb(250, 250, 250) none 0px',
                      }}
                      onClick={processBasicPayment}
                    >
                      <div
                        style={{
                          maxWidth: '100%',
                          cursor: 'pointer',
                          borderWidth: '0px',
                          borderRadius: '8px',
                          backgroundColor: 'rgb(255, 45, 96)',
                          color: 'rgb(250, 250, 250)',
                          boxSizing: 'border-box',
                          padding: '9.6px 24px',
                          transition:
                            'color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s',
                          margin: '0px',
                          fontFamily:
                            '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                          fontSize: '16px',
                          border: '0px none rgb(250, 250, 250)',
                          fontWeight: 600,
                          display: 'flex',
                          width: '100%',
                          placeContent: 'stretch center',
                          WebkitBoxPack: 'center',
                          WebkitBoxAlign: 'center',
                          alignItems: 'center',
                          height: '56px',
                        }}
                      >
                        <span
                          style={{
                            boxSizing: 'border-box',
                            border: '0px solid rgb(229, 231, 235)',
                            opacity: 1,
                            transition: 'opacity 0.15s ease-in-out 0s',
                            margin: '0px',
                          }}
                        >
                          Get Started
                        </span>
                      </div>
                    </button>
                    <p
                      style={{
                        fontSize: '18px',
                        color: 'rgb(144, 152, 162)',
                        fontFamily:
                          '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                        fontWeight: 400,
                        lineHeight: '30.96px',
                        boxSizing: 'border-box',
                        margin: '0px 0px 24px',
                        border: '0px solid rgb(229, 231, 235)',
                      }}
                    >
                      Everything in{' '}
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          fontWeight: 600,
                        }}
                      >
                        Pro
                      </span>
                      , plus:
                    </p>
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '16px',
                        fontWeight: 400,
                        marginTop: '0px',
                        marginBottom: '8px',
                        boxSizing: 'border-box',
                        border: '0px solid rgb(229, 231, 235)',
                        display: 'grid',
                        gridTemplateColumns: '20px auto',
                        gap: '8px',
                        color: 'rgb(250, 250, 250)',
                      }}
                    >
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                        }}
                      ></span>
                      <div
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                        }}
                      >
                        <button
                          data-state="closed"
                          style={{
                            boxSizing: 'border-box',
                            cursor: 'pointer',
                            appearance: 'button',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            backgroundImage: 'none',
                            textTransform: 'none',
                            fontFamily:
                              '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            color: 'rgb(250, 250, 250)',
                            margin: '0px',
                            padding: '0px',
                            border: '0px solid rgb(229, 231, 235)',
                            overflow: 'visible',
                          }}
                        >
                          <div
                            style={{
                              boxSizing: 'border-box',
                              border: '0px solid rgb(229, 231, 235)',
                              textAlign: 'left',
                            }}
                          >
                            <span
                              style={{
                                boxSizing: 'border-box',
                                border: '0px solid rgb(229, 231, 235)',
                                fontWeight: 700,
                                background:
                                  'rgba(0, 0, 0, 0) linear-gradient(to right, rgb(255, 45, 96), rgb(255, 163, 102), rgb(255, 215, 0), rgb(198, 255, 171), rgb(112, 214, 255), rgb(199, 125, 255), rgb(255, 45, 96)) repeat scroll 8.14704% 50% / 200% 100% padding-box',
                                WebkitBackgroundClip: 'text',
                                color: 'rgba(0, 0, 0, 0)',
                                animation:
                                  '4s ease 0s infinite normal none running hLEcf',
                              }}
                            >
                              GPT -4o
                            </span>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '16px',
                        fontWeight: 400,
                        marginTop: '0px',
                        marginBottom: '8px',
                        boxSizing: 'border-box',
                        border: '0px solid rgb(229, 231, 235)',
                        display: 'grid',
                        gridTemplateColumns: '20px auto',
                        gap: '8px',
                        color: 'rgb(250, 250, 250)',
                      }}
                    >
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                        }}
                      ></span>
                      <div
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                        }}
                      >
                        <button
                          data-state="closed"
                          style={{
                            boxSizing: 'border-box',
                            cursor: 'pointer',
                            appearance: 'button',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            backgroundImage: 'none',
                            textTransform: 'none',
                            fontFamily:
                              '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            color: 'rgb(250, 250, 250)',
                            margin: '0px',
                            padding: '0px',
                            border: '0px solid rgb(229, 231, 235)',
                            overflow: 'visible',
                          }}
                        >
                          <div
                            style={{
                              boxSizing: 'border-box',
                              border: '0px solid rgb(229, 231, 235)',
                              textAlign: 'left',
                            }}
                          >
                            Unlimited Credits
                          </div>
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '16px',
                        fontWeight: 400,
                        marginTop: '0px',
                        marginBottom: '8px',
                        boxSizing: 'border-box',
                        border: '0px solid rgb(229, 231, 235)',
                        display: 'grid',
                        gridTemplateColumns: '20px auto',
                        gap: '8px',
                        color: 'rgb(250, 250, 250)',
                      }}
                    >
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                        }}
                      ></span>
                      <div
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                        }}
                      >
                        <button
                          data-state="closed"
                          style={{
                            boxSizing: 'border-box',
                            cursor: 'pointer',
                            appearance: 'button',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            backgroundImage: 'none',
                            textTransform: 'none',
                            fontFamily:
                              '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            color: 'rgb(250, 250, 250)',
                            margin: '0px',
                            padding: '0px',
                            border: '0px solid rgb(229, 231, 235)',
                            overflow: 'visible',
                          }}
                        >
                          <div
                            style={{
                              boxSizing: 'border-box',
                              border: '0px solid rgb(229, 231, 235)',
                              textAlign: 'left',
                            }}
                          >
                            Without watermark
                          </div>
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '16px',
                        fontWeight: 400,
                        marginTop: '0px',
                        marginBottom: '8px',
                        boxSizing: 'border-box',
                        border: '0px solid rgb(229, 231, 235)',
                        display: 'grid',
                        gridTemplateColumns: '20px auto',
                        gap: '8px',
                        color: 'rgb(250, 250, 250)',
                      }}
                    >
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                        }}
                      ></span>
                      <div
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                        }}
                      >
                        <button
                          data-state="closed"
                          style={{
                            boxSizing: 'border-box',
                            cursor: 'pointer',
                            appearance: 'button',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            backgroundImage: 'none',
                            textTransform: 'none',
                            fontFamily:
                              '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            color: 'rgb(250, 250, 250)',
                            margin: '0px',
                            padding: '0px',
                            border: '0px solid rgb(229, 231, 235)',
                            overflow: 'visible',
                          }}
                        >
                          <div
                            style={{
                              boxSizing: 'border-box',
                              border: '0px solid rgb(229, 231, 235)',
                              textAlign: 'left',
                            }}
                          >
                            Download Diagrams
                          </div>
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '16px',
                        fontWeight: 400,
                        marginTop: '0px',
                        marginBottom: '8px',
                        boxSizing: 'border-box',
                        border: '0px solid rgb(229, 231, 235)',
                        display: 'grid',
                        gridTemplateColumns: '20px auto',
                        gap: '8px',
                        color: 'rgb(250, 250, 250)',
                      }}
                    >
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                        }}
                      ></span>
                    </div>
                  </div>
                </section>
                <section
                  style={{
                    background:
                      'radial-gradient(at left top, rgba(246, 73, 66, 0.1), rgba(0, 0, 0, 0)) repeat scroll 0% 0% / auto padding-box border-box, radial-gradient(at right center, rgba(250, 219, 95, 0.1), rgba(0, 0, 0, 0)) repeat scroll 0% 0% / auto padding-box border-box, rgba(0, 0, 0, 0) radial-gradient(at right bottom, rgba(0, 148, 155, 0.15), rgba(0, 0, 0, 0)) repeat scroll 0% 0% / auto padding-box border-box',
                    boxSizing: 'border-box',
                    border: '0px solid rgb(229, 231, 235)',
                    width: '45%',
                    padding: '32px 28px',
                    minHeight: '300px',
                    WebkitBoxAlign: 'center',
                    alignItems: 'center',
                    boxShadow:
                      'rgba(255, 255, 255, 0.15) 0px 1px 3px 0px inset, rgb(33, 40, 49) 0px -1px 3px 0px inset',
                    borderRadius: '24px',
                  }}
                >
                  <div
                    style={{
                      boxSizing: 'border-box',
                      border: '0px solid rgb(229, 231, 235)',
                      marginBottom: '4px',
                    }}
                  >
                    <h3
                      style={{
                        fontWeight: 500,
                        lineHeight: '30.72px',
                        fontSize: '24px',
                        marginTop: '0px',
                        marginBottom: '8px',
                        color: 'rgb(250, 250, 250)',
                        boxSizing: 'border-box',
                        alignItems: 'center',
                        display: 'flex',
                        margin: '0px 0px 8px',
                        border: '0px solid rgb(229, 231, 235)',
                      }}
                    >
                      Pro Annual
                    </h3>
                    <p
                      style={{
                        marginBottom: '0px',
                        boxSizing: 'border-box',
                        border: '0px solid rgb(229, 231, 235)',
                        minHeight: '42px',
                        fontSize: '15px',
                        lineHeight: '21px',
                        color: 'rgb(144, 152, 162)',
                      }}
                    >
                      Business usecase
                    </p>
                  </div>
                  <div
                    style={{
                      boxSizing: 'border-box',
                      border: '0px solid rgb(229, 231, 235)',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <p
                      style={{
                        boxSizing: 'border-box',
                        margin: '0px 0px 4px',
                        border: '0px solid rgb(229, 231, 235)',
                        display: 'flex',
                        WebkitBoxAlign: 'center',
                        alignItems: 'center',
                        marginTop: '0px',
                        marginBottom: '4px',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: '52px',
                          lineHeight: '58px',
                          marginTop: '0px',
                          marginBottom: '8px',
                          color: 'rgb(250, 250, 250)',
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                        }}
                      >
                        <sup
                          style={{
                            boxSizing: 'border-box',
                            top: '-14.3px',
                            fontSize: '28.6px',
                            lineHeight: '0px',
                            position: 'relative',
                            verticalAlign: 'baseline',
                            border: '0px solid rgb(229, 231, 235)',
                          }}
                        >
                          $
                        </sup>
                        19.99
                      </span>
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          fontSize: '14px',
                          marginTop: '-8px',
                          marginLeft: '12px',
                          lineHeight: '18px',
                        }}
                      >
                        per year
                      </span>
                    </p>

                    <button
                      style={{
                        boxSizing: 'border-box',
                        cursor: 'pointer',
                        appearance: 'button',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        backgroundImage: 'none',
                        textTransform: 'none',
                        fontFamily:
                          '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '27.52px',
                        color: 'rgb(250, 250, 250)',
                        margin: '0px',
                        padding: '0px',
                        border: '0px none rgb(250, 250, 250)',
                        overflow: 'visible',
                        display: 'flex',
                        width: '100%',
                        position: 'relative',
                        outline: 'rgb(250, 250, 250) none 0px',
                      }}
                      onClick={processProAnnualPayment}
                    >
                      <div
                        style={{
                          maxWidth: '100%',
                          cursor: 'pointer',
                          borderWidth: '0px',
                          borderRadius: '8px',
                          backgroundColor: 'rgb(255, 45, 96)',
                          color: 'rgb(250, 250, 250)',
                          boxSizing: 'border-box',
                          padding: '9.6px 24px',
                          transition:
                            'color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s',
                          margin: '0px',
                          fontFamily:
                            '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                          fontSize: '16px',
                          border: '0px none rgb(250, 250, 250)',
                          fontWeight: 600,
                          display: 'flex',
                          width: '100%',
                          placeContent: 'stretch center',
                          WebkitBoxPack: 'center',
                          WebkitBoxAlign: 'center',
                          alignItems: 'center',
                          height: '56px',
                        }}
                      >
                        <span
                          style={{
                            boxSizing: 'border-box',
                            border: '0px solid rgb(229, 231, 235)',
                            opacity: 1,
                            transition: 'opacity 0.15s ease-in-out 0s',
                            margin: '0px',
                          }}
                        >
                          Get Started
                        </span>
                      </div>
                    </button>
                    <p
                      style={{
                        fontSize: '18px',
                        color: 'rgb(144, 152, 162)',
                        fontFamily:
                          '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                        fontWeight: 400,
                        lineHeight: '30.96px',
                        boxSizing: 'border-box',
                        margin: '0px 0px 24px',
                        border: '0px solid rgb(229, 231, 235)',
                      }}
                    >
                      Everything in{' '}
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          fontWeight: 600,
                        }}
                      >
                        Pro
                      </span>
                      , plus:
                    </p>
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '16px',
                        fontWeight: 400,
                        marginTop: '0px',
                        marginBottom: '8px',
                        boxSizing: 'border-box',
                        border: '0px solid rgb(229, 231, 235)',
                        display: 'grid',
                        gridTemplateColumns: '20px auto',
                        gap: '8px',
                        color: 'rgb(250, 250, 250)',
                      }}
                    >
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                        }}
                      ></span>
                      <div
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                        }}
                      >
                        <button
                          data-state="closed"
                          style={{
                            boxSizing: 'border-box',
                            cursor: 'pointer',
                            appearance: 'button',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            backgroundImage: 'none',
                            textTransform: 'none',
                            fontFamily:
                              '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            color: 'rgb(250, 250, 250)',
                            margin: '0px',
                            padding: '0px',
                            border: '0px solid rgb(229, 231, 235)',
                            overflow: 'visible',
                          }}
                        >
                          <div
                            style={{
                              boxSizing: 'border-box',
                              border: '0px solid rgb(229, 231, 235)',
                              textAlign: 'left',
                            }}
                          >
                            <span
                              style={{
                                boxSizing: 'border-box',
                                border: '0px solid rgb(229, 231, 235)',
                                fontWeight: 700,
                                background:
                                  'rgba(0, 0, 0, 0) linear-gradient(to right, rgb(255, 45, 96), rgb(255, 163, 102), rgb(255, 215, 0), rgb(198, 255, 171), rgb(112, 214, 255), rgb(199, 125, 255), rgb(255, 45, 96)) repeat scroll 8.14704% 50% / 200% 100% padding-box',
                                WebkitBackgroundClip: 'text',
                                color: 'rgba(0, 0, 0, 0)',
                                animation:
                                  '4s ease 0s infinite normal none running hLEcf',
                              }}
                            >
                              GPT -4o
                            </span>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '16px',
                        fontWeight: 400,
                        marginTop: '0px',
                        marginBottom: '8px',
                        boxSizing: 'border-box',
                        border: '0px solid rgb(229, 231, 235)',
                        display: 'grid',
                        gridTemplateColumns: '20px auto',
                        gap: '8px',
                        color: 'rgb(250, 250, 250)',
                      }}
                    >
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                        }}
                      ></span>
                      <div
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                        }}
                      >
                        <button
                          data-state="closed"
                          style={{
                            boxSizing: 'border-box',
                            cursor: 'pointer',
                            appearance: 'button',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            backgroundImage: 'none',
                            textTransform: 'none',
                            fontFamily:
                              '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            color: 'rgb(250, 250, 250)',
                            margin: '0px',
                            padding: '0px',
                            border: '0px solid rgb(229, 231, 235)',
                            overflow: 'visible',
                          }}
                        >
                          <div
                            style={{
                              boxSizing: 'border-box',
                              border: '0px solid rgb(229, 231, 235)',
                              textAlign: 'left',
                            }}
                          >
                            Unlimited Credits
                          </div>
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '16px',
                        fontWeight: 400,
                        marginTop: '0px',
                        marginBottom: '8px',
                        boxSizing: 'border-box',
                        border: '0px solid rgb(229, 231, 235)',
                        display: 'grid',
                        gridTemplateColumns: '20px auto',
                        gap: '8px',
                        color: 'rgb(250, 250, 250)',
                      }}
                    >
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                        }}
                      ></span>
                      <div
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                        }}
                      >
                        <button
                          data-state="closed"
                          style={{
                            boxSizing: 'border-box',
                            cursor: 'pointer',
                            appearance: 'button',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            backgroundImage: 'none',
                            textTransform: 'none',
                            fontFamily:
                              '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            color: 'rgb(250, 250, 250)',
                            margin: '0px',
                            padding: '0px',
                            border: '0px solid rgb(229, 231, 235)',
                            overflow: 'visible',
                          }}
                        >
                          <div
                            style={{
                              boxSizing: 'border-box',
                              border: '0px solid rgb(229, 231, 235)',
                              textAlign: 'left',
                            }}
                          >
                            Without watermark
                          </div>
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '16px',
                        fontWeight: 400,
                        marginTop: '0px',
                        marginBottom: '8px',
                        boxSizing: 'border-box',
                        border: '0px solid rgb(229, 231, 235)',
                        display: 'grid',
                        gridTemplateColumns: '20px auto',
                        gap: '8px',
                        color: 'rgb(250, 250, 250)',
                      }}
                    >
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                        }}
                      ></span>
                      <div
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                        }}
                      >
                        <button
                          data-state="closed"
                          style={{
                            boxSizing: 'border-box',
                            cursor: 'pointer',
                            appearance: 'button',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            backgroundImage: 'none',
                            textTransform: 'none',
                            fontFamily:
                              '"Work Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            color: 'rgb(250, 250, 250)',
                            margin: '0px',
                            padding: '0px',
                            border: '0px solid rgb(229, 231, 235)',
                            overflow: 'visible',
                          }}
                        >
                          <div
                            style={{
                              boxSizing: 'border-box',
                              border: '0px solid rgb(229, 231, 235)',
                              textAlign: 'left',
                            }}
                          >
                            Download Diagrams
                          </div>
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '16px',
                        fontWeight: 400,
                        marginTop: '0px',
                        marginBottom: '8px',
                        boxSizing: 'border-box',
                        border: '0px solid rgb(229, 231, 235)',
                        display: 'grid',
                        gridTemplateColumns: '20px auto',
                        gap: '8px',
                        color: 'rgb(250, 250, 250)',
                      }}
                    >
                      <span
                        style={{
                          boxSizing: 'border-box',
                          border: '0px solid rgb(229, 231, 235)',
                          display: 'flex',
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                        }}
                      ></span>
                    </div>
                  </div>
                </section>
              </div>
              <div
                style={{
                  marginLeft: '30px',
                  marginRight: '30px',
                  boxSizing: 'border-box',
                  border: '0px solid rgb(229, 231, 235)',
                  display: 'flex',
                  WebkitBoxPack: 'center',
                  justifyContent: 'center',
                  gap: '40px',
                  marginBottom: '100px',
                }}
              ></div>
            </section>
          </section>
        </main>
      </div>
    </div>
  )
}

export default Pricing
